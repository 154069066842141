import { Action, createReducer, on } from '@ngrx/store';
import { ILinkState, Link } from './links.store';
import * as featureActions from './index';

const initialState: ILinkState = {
  error: null,
  links: null,
  searchRequest: null,
  selectedLink: null,
  isLoading: false,
  resultCount: 0,
};

const featureReducer = createReducer<ILinkState>(
  initialState,
  on(featureActions.getLinks, (state, searchRequest) => ({
    ...state,
    links: null,
    error: null,
    searchRequest,
    isLoading: true,
  })),
  on(featureActions.getLinksSuccess, (state, searchResult) => ({
    ...state,
    error: null,
    isLoading: false,
    links: searchResult.searchResults,
    resultCount: searchResult.recordCount,
  })),
  on(featureActions.getSelectedLinkSuccess, (state, { result }) => ({
    ...state,
    error: null,
    selectedLink: result,
  })),
  on(featureActions.saveLink, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  })),
  on(featureActions.saveLinkSuccess, (state) => ({
    ...state,
    error: null,
    isLoading: false,
    links: null,
  })),
  on(featureActions.apiCallFailed, (state, { err }) => ({
    ...state,
    error: err,
    isLoading: false,
  })),
  on(featureActions.createNewLink, (state) => ({
    ...state,
    selectedLink: {
      refNo: 0,
      linkName: null,
      linkUrl: null,
      linkCatagoryId: null,
      linkScope: null,
      linkAttribs: null,
      linkCreatedBy: null,
      linkCreatedOn: null,
      linkUpdatedBy: null,
      linkUpdatedOn: null,
      linkId: null,
      category: null,
    },
  })),
  on(featureActions.deleteLinkSuccess, (state) => ({
    ...state,
    error: null,
    isLoading: false,
    links: null,
  }))
);

export function linkReducer(state = initialState, action: Action) {
  return featureReducer(state, action);
}
