import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent {
  title: string;
  message: string;
  type: DialogType;

  DialogType = DialogType;

  constructor(
    private readonly dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.type = data.type;
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  onDismiss() {
    this.dialogRef.close(false);
  }

  getDialogIcon() {
    switch (this.type) {
      case DialogType.Confirm:
        return 'help';
      case DialogType.Error:
        return 'error';
      case DialogType.Success:
        return 'check';
      case DialogType.Info:
        return 'report';
    }
  }
}

export class ConfirmDialogModel {
  constructor(
    public title: string,
    public message: string,
    public type: DialogType
  ) {}
}

export enum DialogType {
  Confirm,
  Error,
  Success,
  Info,
}
