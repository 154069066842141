import { createAction, emptyProps, props } from '@ngrx/store';
import { UserDetails } from './user.store';

const prefix = '[User]';

export const getUser = createAction(`${prefix} Get User from API`, emptyProps);
export const getUserSuccess = createAction(
  `${prefix} Get User Success`,
  props<{ user: UserDetails }>()
);
export const getUserFailed = createAction(
  `${prefix} Get User Failed`,
  props<{ error: Error }>()
);
export const logout = createAction(`${prefix} Logout current user`, emptyProps);
export const logoutSuccess = createAction(
  `${prefix} Logout success`,
  emptyProps
);
export const logoutFailed = createAction(`${prefix} Logout failed`, emptyProps);
