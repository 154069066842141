import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ILinkState } from './links.store';

export const selectLinkState = createFeatureSelector<ILinkState>('links');
export const selectIsLoading = createSelector(
  selectLinkState,
  (state) => state.isLoading
);
export const selectLinks = createSelector(
  selectLinkState,
  (state) => state.links
);
export const selectSelectedLink = createSelector(
  selectLinkState,
  (state) => state.selectedLink
);
export const selectSearchRequest = createSelector(
  selectLinkState,
  (state) => state.searchRequest
);
export const selectRecordCount = createSelector(
  selectLinkState,
  (state) => state.resultCount
);
