import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IAppState, selectError, selectIsLoading } from './store';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from './services/dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading$!: Observable<boolean>;
  error$!: Observable<Error | null>;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly store: Store<IAppState>,
    private spinnerService: NgxSpinnerService,
    private dialogService: DialogService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe);
  }

  ngOnInit(): void {
    this.isLoading$ = this.store.select(selectIsLoading);
    this.error$ = this.store.select(selectError);
    this.subscriptions.push(
      this.isLoading$.subscribe((result) =>
        result ? this.spinnerService.show() : this.spinnerService.hide()
      )
    );
    this.subscriptions.push(
      this.error$.subscribe((err) => {
        if (err) {
          console.log(err);
          this.dialogService.showErrorDialog(err.name, err.message, 400);
        }
      })
    );
  }
}
