import { createAction, emptyProps, props } from '@ngrx/store';
import { Link, SearchLinkRequest, SearchLinkResponse } from './links.store';

const prefix = '[Links]';

export const getLinks = createAction(
  `${prefix} Get all`,
  props<SearchLinkRequest>()
);
export const getSelectedLink = createAction(
  `${prefix} Get link`,
  props<{ refNo: number }>()
);
export const createNewLink = createAction(`${prefix} Create link`, emptyProps);
export const saveLink = createAction(`${prefix} Update Link`, props<Link>());
export const getLinksSuccess = createAction(
  `${prefix} Get all success`,
  props<SearchLinkResponse>()
);
export const getSelectedLinkSuccess = createAction(
  `${prefix} Get link success`,
  props<{ result: Link }>()
);
export const saveLinkSuccess = createAction(
  `${prefix} Save link success`,
  emptyProps
);
export const deleteLink = createAction(
  `${prefix} Delete Link`,
  props<{ refNo: number }>()
);
export const deleteLinkSuccess = createAction(
  `${prefix} Delete link success`,
  emptyProps
);
export const apiCallFailed = createAction(
  `${prefix} Api return error`,
  props<{ err: Error }>()
);
