<div class="links-mgr-toolbar">
  <a href="/" class="logo">
    <img src="../../../assets/blank.png" />
  </a>
</div>
<mat-toolbar color="primary">
  <mat-toolbar-row class="toolbar-row">
    <span class="breadcrumb">Home</span>
    <span class="example-spacer"></span>
    <div *ngIf="currentUser$ | async as user; else loginTemplate">
      <button
        mat-flat-button
        [matMenuTriggerFor]="userMenu"
        color="primary"
        *ngIf="user"
      >
        <mat-icon>person</mat-icon> Welcome {{ user.firstName }}!
      </button>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="onLogoutButtonClick()">
          <mat-icon>logout</mat-icon>Logout
        </button>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="background-image"></div>
<ng-template #loginTemplate>
  <button
    mat-flat-button
    color="primary"
    (click)="onLoginButtonClick()"
    type="button"
  >
    <mat-icon>login</mat-icon>Login
  </button>
</ng-template>
