import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IUserState, UserDetails } from 'src/app/store/user/user.store';
import * as fromUser from '../../store/user/index';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  currentUser$: Observable<UserDetails | null> | null = null;
  private subscriptions: Subscription[] = [];
  constructor(
    private readonly store: Store<IUserState>,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.store.select(fromUser.selectCurrentUser);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
  onLogoutButtonClick() {
    this.store.dispatch(fromUser.logout());
  }
  onLoginButtonClick() {
    window.location.href = '/secure';
  }
}
