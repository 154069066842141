import { ActionReducerMap, createSelector } from '@ngrx/store';
import { IUserState } from './user/user.store';
import { userReducer } from './user/user.reducers';
import { UserEffects } from './user/user.effects';
import { ILinkState } from './links/links.store';
import { linkReducer } from './links/links.reducers';
import { LinksEffects } from './links/links.effects';
import { ILookupDataState } from './lookup-data/lookup-data.store';
import { lookupDataReducer } from './lookup-data/lookup-data.reducer';
import { LookupDataEffects } from './lookup-data/lookup-data.effects';

export interface IAppState {
  user: IUserState;
  links: ILinkState;
  lookupData: ILookupDataState;
}

export const reducers: ActionReducerMap<IAppState> = {
  user: userReducer,
  links: linkReducer,
  lookupData: lookupDataReducer,
};

export const effects = [UserEffects, LinksEffects, LookupDataEffects];

export const selectState = (state: IAppState) => state;
export const selectIsLoading = createSelector(
  selectState,
  (state: IAppState) => state.links.isLoading
);
export const selectError = createSelector(
  selectState,
  (state: IAppState) => state.links.error ?? state.lookupData.error
);
