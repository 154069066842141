import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStoreModule } from './user/user-store.module';
import { LinksStoreModule } from './links/links-store.module';
import { LookupDataStoreModule } from './lookup-data/lookup-data-store.module';

@NgModule({
  declarations: [],
  imports: [UserStoreModule, LinksStoreModule, LookupDataStoreModule],
})
export class AppStoreModule {}
