import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogModel,
  DialogType,
  MessageDialogComponent,
} from '../components/message-dialog/message-dialog.component';
import { Observable, switchMap, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private readonly dialog: MatDialog) {}
  public showErrorDialog(
    title: string,
    errorMessage: string,
    maxWidth?: number
  ): Observable<any> {
    const dialogData = new ConfirmDialogModel(
      title,
      errorMessage,
      DialogType.Error
    );

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      maxWidth: maxWidth ?? 500,
      data: dialogData,
    });

    return dialogRef.afterClosed().pipe(switchMap((result) => of(result)));
  }

  public showSuccessDialog(
    title: string,
    message: string,
    maxWidth?: number
  ): Observable<any> {
    const dialogData = new ConfirmDialogModel(
      title,
      message,
      DialogType.Success
    );

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      maxWidth: maxWidth ?? 500,
      data: dialogData,
    });

    return dialogRef.afterClosed().pipe(switchMap((result) => of(result)));
  }

  public showInfoDialog(
    title: string,
    message: string,
    maxWidth?: number
  ): Observable<any> {
    const dialogData = new ConfirmDialogModel(title, message, DialogType.Info);

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      maxWidth: maxWidth ?? 500,
      data: dialogData,
    });

    return dialogRef.afterClosed().pipe(switchMap((result) => of(result)));
  }

  public showConfirmDialog(
    title: string,
    message: string,
    maxWidth?: number
  ): Observable<any> {
    const dialogData = new ConfirmDialogModel(
      title,
      message,
      DialogType.Confirm
    );

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      maxWidth: maxWidth ?? 500,
      data: dialogData,
    });

    return dialogRef.afterClosed().pipe(switchMap((result) => of(result)));
  }
}
