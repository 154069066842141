import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { LookupDataEffects } from './lookup-data.effects';
import { lookupDataReducer } from './lookup-data.reducer';
import { StoreModule } from '@ngrx/store';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('lookupData', lookupDataReducer),
    EffectsModule.forFeature([LookupDataEffects]),
  ],
})
export class LookupDataStoreModule {}
