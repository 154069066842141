<div class="table-header-container">
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input
      matInput
      [value]="searchRequest.searchText"
      placeholder="Search"
      (keyup)="searchTextUpdate.next($event)"
      #searchInput
    />
    <button
      *ngIf="
        searchRequest.searchText !== null && searchRequest.searchText.length > 0
      "
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="clearSearch()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <button
    mat-raised-button
    color="primary"
    *ngIf="canEdit"
    type="button"
    (click)="openEditDialog(0)"
  >
    Insert Link
  </button>
</div>
<table
  mat-table
  [dataSource]="dataSource"
  matSort
  (matSortChange)="sortData($event)"
  [matSortActive]="searchRequest.orderBy"
  [matSortDirection]="searchRequest.asc === false ? 'desc' : 'asc'"
>
  <ng-container matColumnDef="linkName">
    <th
      mat-header-cell
      *matHeaderCellDef
      mat-sort-header="linkName"
      width="40%"
    >
      Link Name
    </th>
    <td mat-cell *matCellDef="let row" class="linkName-cell">
      <div class="linkName-container">
        {{ row.linkName }}
        <button mat-icon-button [mat-menu-trigger-for]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            type="button"
            (click)="copyUrlToClipboard(row.refNo)"
          >
            <mat-icon>content_copy</mat-icon>
            Copy Link
          </button>
          <button
            mat-menu-item
            type="button"
            (click)="openLinkNewTab(row.refNo)"
          >
            <mat-icon>open_in_browser</mat-icon>
            Test Link
          </button>
          <button
            mat-menu-item
            *ngIf="canEdit"
            type="button"
            (click)="openEditDialog(row.refNo)"
          >
            <mat-icon>edit</mat-icon>
            Edit Link
          </button>
        </mat-menu>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="linkUrl">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="linkUrl" width="40%">
      Link Url
    </th>
    <td mat-cell *matCellDef="let row" class="linkUrl-cell">
      {{ row.linkUrl }}
    </td>
  </ng-container>
  <ng-container matColumnDef="category" width="10%">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="category">
      Category
    </th>
    <td mat-cell *matCellDef="let row" class="category-cell">
      {{ row.category?.categoryName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="linkAttributes" width="10%">
    <th mat-header-cell *matHeaderCellDef>Link Attr</th>
    <td mat-cell *matCellDef="let row">
      {{ row.linkAttribs }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row">
      <div class="actions-container">
        <button
          mat-icon-button
          type="button"
          (click)="copyUrlToClipboard(row.refNo)"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <button
          mat-icon-button
          type="button"
          *ngIf="canEdit"
          (click)="openEditDialog(row.refNo)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          mat-menu-item
          type="button"
          (click)="openLinkNewTab(row.refNo)"
        >
          <mat-icon>open_in_browser</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
  <tr
    mat-header-row
    *matHeaderRowDef="displayColumns; sticky: true"
    class="table-header-row"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayColumns"
    class="table-row"
  ></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">No links found</td>
  </tr>
</table>
<div class="table-footer-container">
  <mat-paginator
    [length]="resultCount"
    [pageSizeOptions]="[25, 50]"
    [pageSize]="searchRequest.pageSize"
    showFirstLastButtons
    aria-label="Select page"
    (page)="onPageChange($event)"
    [pageIndex]="searchRequest.pageNo - 1"
  >
  </mat-paginator>
</div>
