import { createReducer, on } from '@ngrx/store';
import { ILookupDataState } from './lookup-data.store';
import * as featureActions from './index';
import { state } from '@angular/animations';
import { result } from 'lodash';

export const initialState: ILookupDataState = {
  categories: null,
  isLoading: false,
  error: undefined,
};

export const lookupDataReducer = createReducer<ILookupDataState>(
  initialState,
  on(featureActions.getCategories, (state) => ({ ...state, isLoading: true })),
  on(featureActions.getCategoriesSuccess, (state, { result }) => ({
    ...state,
    isLoading: false,
    categories: result,
  }))
);
