import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUserState } from './user.store';

export const selectUserState = createFeatureSelector<IUserState>('user');
export const selectIsLoading = createSelector(
  selectUserState,
  (state) => state.isLoading
);
export const selectCurrentUser = createSelector(
  selectUserState,
  (state) => state.currentUser
);
