<div class="dialog-header">
  <h1 mat-dialog-title>
    {{ title }}
  </h1>
  <mat-icon>
    {{ getDialogIcon() }}
  </mat-icon>
</div>
<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions class="dialog-footer">
  <button
    mat-button
    (click)="onDismiss()"
    *ngIf="type === this.DialogType.Confirm"
  >
    No
  </button>
  <button mat-raised-button color="primary" (click)="onConfirm()">
    {{ type === this.DialogType.Confirm ? "Yes" : "OK" }}
  </button>
</div>
