import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Link, SearchLinkRequest } from 'src/app/store/links/links.store';
import {
  Observable,
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  of,
} from 'rxjs';
import { UserDetails } from 'src/app/store/user/user.store';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store';
import * as fromUsers from 'src/app/store/user/index';
import * as fromLinks from 'src/app/store/links/index';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { EditFormComponent } from '../edit-form/edit-form.component';

@Component({
  selector: 'link-table',
  templateUrl: './link-table.component.html',
  styleUrls: ['./link-table.component.scss'],
})
export class LinkTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data!: Link[];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @Output() onSearchRequestChanged: EventEmitter<SearchLinkRequest> =
    new EventEmitter();

  currentUser$: Observable<UserDetails | null> | null = null;
  dataSource: MatTableDataSource<Link> = new MatTableDataSource<Link>();
  displayColumns = ['linkName', 'linkUrl', 'category'];
  searchRequest$!: Observable<SearchLinkRequest | null>;
  resultCount$!: Observable<number>;
  resultCount = 0;
  canEdit: boolean = false;
  searchRequest: SearchLinkRequest = {
    asc: true,
    pageNo: 1,
    orderBy: 'linkName',
    pageSize: 25,
    searchText: '',
  };

  searchTextUpdate = new Subject<Event>();

  private readonly appRoleName = 'LinksManager_Users';
  private readonly redirectEndPoint = 'public/redirect.aspx?linkId';
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly store: Store<IAppState>,
    private readonly dialog: MatDialog,
    private readonly toastr: ToastrService
  ) {
    this.getSearchRequest();
    this.getCurrentUser();
    this.getResultCount();
    this.applyFilter();
    this.getSearchRequest();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
  }

  ngOnInit(): void {
    this.dataSource.data = this.data;
  }

  sortData(sort: Sort) {
    this.searchRequest = {
      ...this.searchRequest,
      orderBy: sort.active,
      asc: sort.direction !== 'desc',
    };

    this.sort.active = sort.active;
    this.sort.direction = sort.direction;

    this.onSearchRequestChanged.emit(this.searchRequest);
  }

  onPageChange(event: PageEvent) {
    this.searchRequest = {
      ...this.searchRequest,
      pageNo: event.pageIndex + 1,
      pageSize: event.pageSize,
    };

    this.onSearchRequestChanged.emit(this.searchRequest);
  }

  applyFilter() {
    this.subscriptions.push(
      this.searchTextUpdate
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((event) => {
          const searchInput = event.target as HTMLInputElement;
          const filterValue = searchInput.value;
          if (filterValue !== this.searchRequest.searchText) {
            this.searchRequest = {
              ...this.searchRequest,
              searchText: filterValue,
              pageNo: 1,
            };
            this.onSearchRequestChanged.emit(this.searchRequest);
          }
        })
    );
  }

  getResultCount() {
    this.resultCount$ = this.store.select(fromLinks.selectRecordCount);

    this.subscriptions.push(
      this.resultCount$.subscribe((result) => (this.resultCount = result))
    );
  }

  getCurrentUser() {
    this.currentUser$ = this.store.select(fromUsers.selectCurrentUser);

    this.subscriptions.push(
      this.currentUser$.subscribe((result) => {
        if (result) {
          this.canEdit = result.operations?.includes(
            this.appRoleName
          ) as boolean;
        } else this.canEdit = false;
      })
    );
  }

  getSearchRequest() {
    this.searchRequest$ = this.store.select(fromLinks.selectSearchRequest);

    this.subscriptions.push(
      this.searchRequest$.subscribe((result) => {
        if (result) {
          this.searchRequest = result;
        }
      })
    );
  }

  clearSearch() {
    this.searchRequest = { ...this.searchRequest, searchText: '' };
    this.onSearchRequestChanged.emit(this.searchRequest);
  }

  copyUrlToClipboard(refNo: number) {
    const url = `${window.location.href}${this.redirectEndPoint}=${refNo}`;

    navigator.clipboard.writeText(url);
    this.toastr.success('Url copied.');
  }

  openLinkNewTab(refNo: number) {
    const url = `${window.location.href}${this.redirectEndPoint}=${refNo}`;
    window.open(url);
  }

  openEditDialog(refNo: number) {
    if (refNo === 0) this.store.dispatch(fromLinks.createNewLink());
    else this.store.dispatch(fromLinks.getSelectedLink({ refNo: refNo }));

    const dialog = this.dialog.open(EditFormComponent, {
      width: '500px',
    });

    dialog.afterClosed().subscribe((result: Link | null) => {
      if (result) {
        this.searchRequest = { ...this.searchRequest, searchText: '' };
        this.onSearchRequestChanged.emit(this.searchRequest);
        this.store.dispatch(fromLinks.getLinks(this.searchRequest));
      }
    });
  }
}
