import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromUser from './index';
import { map, switchMap, catchError, of } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { IUserState, UserDetails } from './user.store';
import { Store } from '@ngrx/store';

@Injectable()
export class UserEffects {
  private authEndPoint: string = '/api/auth';
  private logoutPath: string = '/signout-oidc';
  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly store: Store<IUserState>
  ) {}

  getUser = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUser.getUser.type),
      switchMap(() => this.apiService.get<UserDetails>(this.authEndPoint)),
      map((user: UserDetails) => fromUser.getUserSuccess({ user })),
      catchError((error, caught) => {
        this.store.dispatch(
          fromUser.getUserFailed({
            error: {
              message: error.error,
              name: 'Failed when getting user information',
            },
          })
        );
        return this.apiService.get<any>(this.logoutPath);
      })
    )
  );

  logOut = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUser.logout.type),
      switchMap(() => this.apiService.get<any>(this.logoutPath)),
      map(() => fromUser.logoutSuccess()),
      catchError((error, caught) => {
        this.store.dispatch(fromUser.logoutFailed());
        return of();
      })
    )
  );
}
