import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromLookupData from './index';
import { catchError, map, switchMap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ILookupDataState, LnkCategory } from './lookup-data.store';
import { Store } from '@ngrx/store';

@Injectable()
export class LookupDataEffects {
  private lookupDateEndPoint: string = '/api/lookupData/categories';

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<ILookupDataState>
  ) {}

  getCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLookupData.getCategories.type),
      switchMap(() =>
        this.apiService.get<Array<LnkCategory>>(this.lookupDateEndPoint)
      ),
      map((categories: Array<LnkCategory>) =>
        fromLookupData.getCategoriesSuccess({ result: categories })
      ),
      catchError((error: any, caught) => {
        this.store.dispatch(
          fromLookupData.getCategoriesFailed({
            err: {
              message: error.error,
              name: 'Failed when getting categories',
            },
          })
        );
        throw caught;
      })
    )
  );
}
