import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ILookupDataState } from './lookup-data.store';

export const selectLookupDataState =
  createFeatureSelector<ILookupDataState>('lookupData');

export const selectCategories = createSelector(
  selectLookupDataState,
  (state) => state.categories
);

export const selectIsLoading = createSelector(
  selectLookupDataState,
  (state) => state.isLoading
);
