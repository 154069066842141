import { createAction, emptyProps, props } from '@ngrx/store';
import { LnkCategory } from './lookup-data.store';

const prefix = '[LookupData]';

export const getCategories = createAction(
  `${prefix} Get Link Categories`,
  emptyProps
);
export const getCategoriesSuccess = createAction(
  `${prefix} Get Link Categories Success`,
  props<{ result: Array<LnkCategory> }>()
);
export const getCategoriesFailed = createAction(
  `${prefix} Get Link Categories Failed`,
  props<{ err: Error }>()
);
