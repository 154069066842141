import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LinksEffects } from './links.effects';
import { linkReducer } from './links.reducers';

@NgModule({
  imports: [
    StoreModule.forFeature('links', linkReducer),
    EffectsModule.forFeature([LinksEffects]),
  ],
})
export class LinksStoreModule {}
