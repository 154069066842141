import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { IAppState } from 'src/app/store';
import { Link } from 'src/app/store/links/links.store';
import * as fromLink from 'src/app/store/links/index';
import * as fromLookup from 'src/app/store/lookup-data/index';
import { LnkCategory } from 'src/app/store/lookup-data/lookup-data.store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { Actions, ofType } from '@ngrx/effects';
@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss'],
})
export class EditFormComponent implements OnInit, OnDestroy {
  selectedLink$: Observable<Link | null> | null = null;
  categories$: Observable<LnkCategory[] | null> | null = null;
  saveResult$: Observable<boolean> | null = null;

  subscriptions: Subscription[] = [];

  linkForm = new FormGroup({
    refNo: new FormControl<number>(0),
    linkCatagoryId: new FormControl<number | null>(null),
    linkName: new FormControl<string>('', [Validators.required]),
    linkUrl: new FormControl<string>('', [Validators.required]),
  });

  private editedLink!: Link;
  private destroyed$ = new Subject<boolean>();

  constructor(
    private readonly store: Store<IAppState>,
    private readonly dialogRef: MatDialogRef<EditFormComponent>,
    private readonly dialogService: DialogService,
    private action$: Actions
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  ngOnInit(): void {
    this.selectedLink$ = this.store.select(fromLink.selectSelectedLink);
    this.categories$ = this.store.select(fromLookup.selectCategories);

    this.loadLookupData();
    this.initForm();
    this.actionSubscriptions();
  }

  submit(): void {
    this.editedLink = {
      ...this.editedLink,
      linkCatagoryId: this.linkForm.value.linkCatagoryId ?? null,
      linkName: this.linkForm.value.linkName as string,
      linkUrl: this.linkForm.value.linkUrl as string,
      linkId: 0,
      category: null,
    };
    this.store.dispatch(fromLink.saveLink(this.editedLink));
  }

  onDeleteButtonClicked(refNo: number) {
    const dialogResult = this.dialogService.showConfirmDialog(
      `Deleting ${this.editedLink.linkName}`,
      'Are you sure you want to delete this link?'
    );

    this.subscriptions.push(
      dialogResult.subscribe((result) => {
        if (result) {
          this.store.dispatch(fromLink.deleteLink({ refNo }));
        }
      })
    );
  }

  private loadLookupData() {
    this.store.dispatch(fromLookup.getCategories());
  }

  private initForm() {
    if (this.selectedLink$) {
      this.subscriptions.push(
        this.selectedLink$.subscribe((link) => {
          if (link) {
            this.editedLink = link;
            this.linkForm.patchValue({ ...link });
          }
        })
      );
    }
  }

  private actionSubscriptions() {
    this.subscriptions.push(
      this.action$
        .pipe(ofType(fromLink.saveLinkSuccess.type), takeUntil(this.destroyed$))
        .subscribe(() => this.showDialogAndClose('Success', 'Save Successful.'))
    );

    this.subscriptions.push(
      this.action$
        .pipe(
          ofType(fromLink.deleteLinkSuccess.type),
          takeUntil(this.destroyed$)
        )
        .subscribe(() =>
          this.showDialogAndClose('Success', 'Delete Successful')
        )
    );
  }

  private showDialogAndClose(title: string, message: string) {
    this.subscriptions.push(
      this.dialogService
        .showSuccessDialog(title, message)
        .subscribe(() => this.dialogRef.close(this.editedLink))
    );
  }
}
