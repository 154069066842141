import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiService } from 'src/app/services/api.service';
import { ILinkState, Link, SearchLinkResponse } from './links.store';
import { Store } from '@ngrx/store';
import * as fromLinks from './index';
import { catchError, switchMap, map, of, tap, withLatestFrom } from 'rxjs';

@Injectable()
export class LinksEffects {
  private linkEndPoint: string = '/api/links';

  constructor(
    private readonly action$: Actions,
    private readonly apiService: ApiService,
    private readonly store: Store<ILinkState>
  ) {}

  getLinks = createEffect(() =>
    this.action$.pipe(
      ofType(fromLinks.getLinks.type),
      withLatestFrom(this.store.select(fromLinks.selectSearchRequest)),
      switchMap(([never, searchRequest]) =>
        this.apiService.post<SearchLinkResponse>(
          `${this.linkEndPoint}/search`,
          searchRequest
        )
      ),
      map((result: SearchLinkResponse) => fromLinks.getLinksSuccess(result)),
      catchError((error: any, caught) => {
        this.store.dispatch(
          fromLinks.apiCallFailed({
            err: {
              message: error.error,
              name: 'Failed when getting links',
            },
          })
        );
        throw caught;
      })
    )
  );

  getLink = createEffect(() =>
    this.action$.pipe(
      ofType(fromLinks.getSelectedLink.type),
      switchMap(({ refNo }) =>
        this.apiService.get<Link>(`${this.linkEndPoint}/${refNo}`)
      ),
      map((link) => fromLinks.getSelectedLinkSuccess({ result: link })),
      catchError((error: any, caught) => {
        this.store.dispatch(
          fromLinks.apiCallFailed({
            err: {
              message: error.error,
              name: 'Failed when getting link',
            },
          })
        );
        throw caught;
      })
    )
  );

  saveLink = createEffect(() =>
    this.action$.pipe(
      ofType(fromLinks.saveLink.type),
      switchMap((link: Link) =>
        link
          ? link.refNo === 0
            ? this.apiService.post<any>(this.linkEndPoint, link)
            : this.apiService.put<any>(
                `${this.linkEndPoint}/${link.refNo}`,
                link
              )
          : of(null)
      ),
      map(() => fromLinks.saveLinkSuccess()),
      catchError((error: any, caught) => {
        this.store.dispatch(
          fromLinks.apiCallFailed({
            err: {
              message: error.error,
              name: 'Failed when saving link',
            },
          })
        );
        throw caught;
      })
    )
  );

  deleteLink = createEffect(() =>
    this.action$.pipe(
      ofType(fromLinks.deleteLink.type),
      switchMap(({ refNo }) =>
        this.apiService.delete(`${this.linkEndPoint}/${refNo}`)
      ),
      map(() => fromLinks.deleteLinkSuccess()),
      catchError((error: any, caught) => {
        this.store.dispatch(
          fromLinks.apiCallFailed({
            err: {
              message: error.error,
              name: 'Failed when deleting link',
            },
          })
        );
        throw caught;
      })
    )
  );
}
