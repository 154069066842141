import { Action, createReducer, on } from '@ngrx/store';
import { IUserState } from './user.store';
import * as featureActions from './index';

const initialState: IUserState = {
  currentUser: null,
  error: null,
  isLoading: false,
};

const featureReducer = createReducer<IUserState>(
  initialState,
  on(featureActions.getUser, (state) => ({ ...state, isLoading: true })),
  on(featureActions.getUserFailed, (state, { error }) => ({
    ...state,
    currentUser: null,
    isLoading: false,
    error,
  })),
  on(featureActions.getUserSuccess, (state, { user }) => ({
    ...state,
    currentUser: user,
    isLoading: false,
    error: null,
  })),
  on(featureActions.logoutSuccess, (state) => ({
    ...state,
    currentUser: null,
  })),
  on(featureActions.logoutFailed, (state) => ({ ...state, currentUser: null }))
);

export function userReducer(state = initialState, action: Action) {
  return featureReducer(state, action);
}
