<form
  [formGroup]="linkForm"
  (submit)="submit()"
  *ngIf="selectedLink$ | async as link"
>
  <h1 mat-dialog-title>Edit Link</h1>
  <div mat-dialog-content class="form-container">
    <mat-form-field>
      <mat-label>Link name</mat-label>
      <input matInput formControlName="linkName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Url</mat-label>
      <input matInput formControlName="linkUrl" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Category</mat-label>
      <mat-select formControlName="linkCatagoryId">
        <mat-option
          *ngFor="let category of categories$ | async"
          [value]="category.categoryId"
          >{{ category.categoryName }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <mat-dialog-actions class="actions-container">
    <button
      *ngIf="link.refNo !== 0"
      mat-raised-button
      type="button"
      (click)="onDeleteButtonClicked(link.refNo)"
    >
      Delete
    </button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="!linkForm.valid"
    >
      Save and Close
    </button>
    <button mat-raised-button type="button" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>
