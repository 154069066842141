import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private httpClient: HttpClient) {}
  public get<T>(url: string, options?: any): Observable<T> {
    // @ts-ignore
    return this.httpClient.get(url, options);
  }
  public post<T>(url: string, data: any, options?: any): Observable<T> {
    // @ts-ignore
    return this.httpClient.post(url, data, options);
  }
  public put<T>(url: string, data: any, options?: any): Observable<T> {
    // @ts-ignore
    return this.httpClient.put(url, data, options);
  }
  public delete<T>(url: string, options?: any): Observable<T> {
    // @ts-ignore
    return this.httpClient.delete(url, options);
  }
}
