import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedComponentModule } from './components/shared-compoenents.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { IAppState, reducers } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppStoreModule } from './store/store.module';
import { HttpClientModule } from '@angular/common/http';

function localStorageSyncReducer(
  reducer: ActionReducer<IAppState>
): ActionReducer<IAppState> {
  return localStorageSync({
    keys: [
      { user: ['currentUser'] },
      { links: ['selectedLink'] },
      {
        lookupData: ['categories'],
      },
    ],
    rehydrate: true,
    removeOnUndefined: true,
    storage: sessionStorage,
  })(reducer);
}

const metaReducers: Array<MetaReducer<IAppState, any>> = [
  localStorageSyncReducer,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedComponentModule,
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument(),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(),
    AppStoreModule,
    NgxSpinnerModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
